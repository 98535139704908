import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`I denna sommarspecial snackar vi om hur Trevlig Mjukvara produceras, vad som har fungerat bra och vad som har fungerat mindre bra, vad som har varit roligt och vad som varit mindre roligt. Vi avslutar med att se på hur framtiden ser ut och vilka mål och funderingar som finns.`}</p>
    <h2>{`Hur produceras Trevlig Mjukvara?`}</h2>
    <ul>
      <li parentName="ul">{`Mjukvara`}</li>
      <li parentName="ul">{`Hårdvara`}</li>
      <li parentName="ul">{`Förbättringar`}</li>
    </ul>
    <h2>{`Vad har fungerat bra?`}</h2>
    <ul>
      <li parentName="ul">{`Schema`}</li>
      <li parentName="ul">{`Innehåll`}</li>
      <li parentName="ul">{`Vårt community`}</li>
    </ul>
    <h2>{`Vad har fungerat mindre bra?`}</h2>
    <ul>
      <li parentName="ul">{`Schema`}</li>
      <li parentName="ul">{`Editering`}</li>
      <li parentName="ul">{`Att nå vår publik`}</li>
      <li parentName="ul">{`Marknadsföring`}</li>
    </ul>
    <h2>{`Vad har varit roligt?`}</h2>
    <ul>
      <li parentName="ul">{`Inspelning`}</li>
      <li parentName="ul">{`Bloopers`}</li>
      <li parentName="ul">{`Lära sig om FOSS`}</li>
      <li parentName="ul">{`Skapa innehåll och dela det`}</li>
      <li parentName="ul">{`Feedback`}</li>
    </ul>
    <h2>{`Vad har varit mindre roligt?`}</h2>
    <ul>
      <li parentName="ul">{`Press att leverera konsistent`}</li>
      <li parentName="ul">{`Betala för tredjeparts-tjänster`}</li>
    </ul>
    <h2>{`Hur ser framtiden ut för podcasten?`}</h2>
    <ul>
      <li parentName="ul">{`Skala upp`}</li>
      <li parentName="ul">{`Konsekvens`}</li>
      <li parentName="ul">{`Nya idéer`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Intro: Sommar, sommar, sommar - Skriven av Sten Carlberg. Inspelad 1952 av Åke Jelvings orkester`}</li>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      